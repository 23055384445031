import React from "react"
import Fade from "react-reveal/Fade"
import "./style.scss"

const Skills = () => {

	return (
		<div className="skills">
            <div className="container">
                <Fade bottom duration={1000}>
                    <span className="caption skills__caption">Areas of expertise</span>
                </Fade>
                <div className="skills__wrap">
                    <ul className="skills__list">
                        <Fade bottom duration={1000}>
                            <li>Digital Transformation / Replatforming</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Robotic Process Automation (RPA)</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Cache Distribution Networks (CDN)</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Web, Mobile, and Native app development</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Testing automation</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>e-Commerce</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Internal Process Developments</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>ERP / CRM</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Web Performance Improvement (WPO)</li>
                        </Fade>
                    </ul>
                </div>
                <Fade bottom duration={1000}>
                    <span className="caption skills__caption">Skills</span>
                </Fade>
                <div className="skills__wrap">
                    <ul className="skills__list">
                        <Fade bottom duration={1000}>
                            <li>Modernizing & Re-Platforming Legacy Systems</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Native & Cross-Platform Development, .NET, C#, Python, React Angular, jQuery</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Cross-Platform Development: React Native, Xamarin</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Comprehensive Cloud Deployments</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>System Design and Architecture</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>Odoo</li>
                        </Fade>
                    </ul>
                </div>
            </div>
		</div>
	)
}

export default Skills
