import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import "./style.scss"

const ourProcessList = [
	{
		id: '5',
		label: 'Planning',
		title: 'Planning is crucial to success.',
		text: "It's simple. We start with you and listen to what the key needs are for your business. From there, we can determine the talent and resources needed from our end and can provide you a customized estimated timeline for delivery."
	},
	{
		id: '1',
		label: 'Implementation',
		title: 'Implementation is the beginning of everything.',
		text: "It's go-time! We have a solid, systematic approach leveraging years of launch expertise where depending on your preference; we can work independently or as codependents as we move thru the project deliverables."
	},
	{
		id: '2',
		label: 'Testing',
		title: 'Testing all moving parts in order to deliver a best-in-class digital product.',
		text: 'All hands-on deck mentality so that no detail goes missed. After all, it really is the creative details that make our work stand out from the rest. We welcome collaboration throughout this process to ensure a consistent brand message from start to finish.'
	},
	{
		id: '3',
		label: 'Launch',
		title: 'Launch that will exceed your expectations.',
		text: 'When you partner with Cider, you can expect a stellar digital product delivered on spec and, most importantly, on time.'
	},
	{
		id: '4',
		label: 'Support',
		title: 'Support is available right at your fingertips.',
		text: 'Our Team is available to you 100%. You can plan on weekly, organized discussions with all necessary parties as we roll thru your project.'
	}
]

const OurProcess = () => {
	const [activeTab, setActiveTab] = useState(0)

	return (
		<div className="our-process">
			<div className="container">
				<Fade bottom duration={1000}>
					<span className="caption our-process__caption">Our Process</span>
				</Fade>
				<div className="our-process__wrap">
					<ul className="number-list our-process__nav">
						{ourProcessList.map((item, index) => {
							return (
								<li
									className={`our-process__item ${activeTab === index ? 'active' : ''}`}
									key={item.id}
								>
									<span onMouseEnter={() => setActiveTab(index)}>{item.label}</span>
								</li>
							)
						})}
					</ul>
					<div className="our-process__content">
						<span className="title our-process__title">
							{ourProcessList[activeTab].title}
						</span>
						<div className="our-process__text">
							<p>{ourProcessList[activeTab].text}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OurProcess
