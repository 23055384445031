import React, { useRef, useEffect, useState } from "react"
import Layout from "../components/Layout"
import Heading from "../components/Heading"
import SEO from "../components/seo"
import Loader from "../components/Loader"
import Skills from "../components/Skills"
import Process from "../components/Process"
import OurProcess from "../components/OurProcess"
import "../styles/pages-style/what-we-do.scss"
import img from "../images/new-what-we-do.svg"

const WhatWeDo = () => {
    const skillsBlock = useRef(null)
    const [offsetSkills, setOffsetSkills] = useState(0)
    const [bgChangeSkills, setBgChangeSkills] = useState(false)

    const ourProcessBlock = useRef(null)
    const [offsetOurProcess, setOffsetOurProcess] = useState(0)
    const [bgChangeOurProcess, setBgChangeOurProcess] = useState(false)

    const handleScroll = (e) => {
        let offsetTop = window.pageYOffset
        let windowHeight = window.innerHeight

        offsetTop + (windowHeight / 2) > offsetSkills ? setBgChangeSkills(true) : setBgChangeSkills(false)
        offsetTop + (windowHeight / 2) > offsetOurProcess ? setBgChangeOurProcess(true) : setBgChangeOurProcess(false)
    }

    useEffect(() => {
        let bodyRect = document.body.getBoundingClientRect()

        let skillsRect = skillsBlock.current.getBoundingClientRect()
        let skillsPos = skillsRect.top - bodyRect.top
        setOffsetSkills(skillsPos)

        let ourProcessRect = ourProcessBlock.current.getBoundingClientRect()
        let ourProcessPos = ourProcessRect.top - bodyRect.top
        setOffsetOurProcess(ourProcessPos)

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }

    }, [offsetSkills, offsetOurProcess, handleScroll])

    const [animate, setAnimate] = useState(true)

    function changeLoad() {
        setTimeout(() => {
            setAnimate(false)
        }, 500)
    }

    useEffect(() => {
        window.addEventListener('load', changeLoad(), false)
    }, [])

    return (
        <div>
            {!animate ? null : <Loader />}
            <Layout>
                <SEO title="Services | Cider Consulting" />
                <div>
                    <Heading
                        caption="What We Do"
                        text="Cracking the code to optimal business practices"
                        img={img}
                    />
                    <div ref={skillsBlock} className={bgChangeSkills ? 'change-bg-skills' : ''}>
                        <Skills />
                    </div>
                    <div ref={ourProcessBlock} className={bgChangeOurProcess ? 'change-bg-our-process' : ''}>
                        <OurProcess />
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default WhatWeDo
