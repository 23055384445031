import React, { useState, useEffect } from "react"
import Fade from "react-reveal/Fade"
import "../styles/list.css"

let titleText = "Defining users and business golas"

class Process extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "Defining users and business golas",
      translate: 0,
      color: "#FFF6DD",
      clicked: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
  }
  handleScroll() {
    let element = document.getElementById("headingProcess")
    let rect = element.getBoundingClientRect()
    let scrollPercent = (rect.y / window.innerHeight) * 100
    let xOffSet = (scrollPercent - 10) * 4
    if (xOffSet < 0) {
      xOffSet = 0
    } else if (scrollPercent > 120) {
      xOffSet = 120 * 4
    }
    this.setState({
      translate: xOffSet,
      color: "#FFF6DD",
      title: this.state.title,
      clicked: this.state.clicked,
    })
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }
  handleClick(e) {
    e.preventDefault()
    let newTitle = e.target.textContent + " is crucial to success"
    this.setState({
      title: newTitle,
      translate: this.state.translate,
      color: this.state.color,
      clicked: true,
    })
  }
  handleMouseLeave(e) {
    e.preventDefault()
    if (!this.state.clicked) {
      let newTitle = "Defining users and business golas"
      this.setState({
        title: newTitle,
        translate: this.state.translate,
        color: this.state.color,
        clicked: this.state.clicked,
      })
    }
  }
  handleMouseEnter(e) {
    e.preventDefault()

    if (!this.state.clicked) {
      let word = e.target.textContent;
      let numberCheck = /\d/.test(word)
      console.log(numberCheck)
      if (numberCheck) {
        word = word.substring(1);
      }
      let newTitle = word + " is crucial to success"
      this.setState({
        title: newTitle,
        translate: this.state.translate,
        color: this.state.color,
        clicked: this.state.clicked,
      })
    }
  }
  render() {
    return (
      <div className="bg-sand overflow-hidden" id="process">
        <div className="lg:w-fourteen lg:pb-40 lg:pt-30 md:w-ten lg:px-32 md:px-16 md:pt-20 md:pb-25 sm:px-16 sm:py-20 px-5 py-15 mx-auto">
          <h2
            className="lg:text-160 md:text-140 sm:text-100 text-60 lg:mb-25 md:mb-20 sm:mb-15 mb-8 subline font-knockout text-sand -mr-100"
            style={{
              transform: `translateX(${this.state.translate}px)`,
              color: this.state.color,
            }}
            id="headingProcess"
          >
            Process
          </h2>
          <div className="flex flex-col sm:flex-row justify-between">
            <Fade duration={1300}>
              <div className="md:mb-0 mb-10">
                <ul className="lg:text-30 md:text-25 sm:text-20">
                  <li
                    className="cursor-pointer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleClick}
                  >
                    <div className="process">
                      <div className="bg-read h-7 w-7 md:h-8 md:w-8 bg-opacity-15 items-center flex rounded-full pt-1 justify-center">
                        1
                      </div>
                      <div
                        className="word"
                        id="Planning"
                        style={{ background: "none" }}
                      >
                        Planning
                      </div>
                    </div>
                  </li>
                  <li
                    className="cursor-pointer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleClick}
                  >
                    <div className="process">
                      <div className="bg-read h-7 w-7 md:h-8 md:w-8 bg-opacity-15 items-center flex rounded-full pt-1s sm:pt-1 justify-center">
                        2
                      </div>
                      <div
                        className="word"
                        id="Implementation"
                        style={{ background: "none" }}
                      >
                        Implementation
                      </div>
                    </div>
                  </li>
                  <li
                    className="cursor-pointer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleClick}
                  >
                    <div className="process">
                      <div className="bg-read h-7 w-7 md:h-8 md:w-8 bg-opacity-15 items-center flex rounded-full pt-1s sm:pt-1 justify-center">
                        3
                      </div>
                      <div
                        className="word"
                        id="Testing"
                        style={{ background: "none" }}
                      >
                        Testing
                      </div>
                    </div>
                  </li>
                  <li
                    className="cursor-pointer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleClick}
                  >
                    <div className="process">
                      <div
                        className="bg-read h-7 w-7 md:h-8 md:w-8 bg-opacity-15 items-center flex rounded-full pt-1s sm:pt-1 justify-center"
                        style={{ paddingRight: "1px" }}
                      >
                        4
                      </div>
                      <div
                        className="word"
                        id="Support"
                        style={{ background: "none" }}
                      >
                        Support
                      </div>
                    </div>
                  </li>
                  <li
                    className="cursor-pointer"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleClick}
                  >
                    <div className="process">
                      <div className="bg-read h-7 w-7 md:h-8 md:w-8 transition duration-300 bg-opacity-15 items-center flex rounded-full pt-1 justify-center">
                        5
                      </div>
                      <div
                        className="word"
                        id="Kickoff"
                        style={{ background: "none" }}
                      >
                        Kickoff
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Fade>
            <Fade duration={1300}>
              <div className="sm:w-1/2">
                <h3 className="lg:text-2xl md:text-1xl sm:text-30 text-24 font-sofia-bold md:mb-6 mb-3">
                  {this.state.title}
                </h3>
                <p className="text-read2 lg:text-lg md:text-base2 text-18l">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent ultrices, eros eget laoreet commodo, erat nisl
                  elementum dui, sed volutpat diam felis vestibulum mi.
                  Suspendisse vel dignissim quam. Fusce id justo dui. Nullam
                  quis elit et nisl pellentesque sagittis vitae id ligula.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
  }
}

export default Process
